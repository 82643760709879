import {useState} from "react";
import {useRouter} from "next/router";
import MobileHeaderModalSearch from "../../../home/home/MobileHeaderModalSearch/MobileHeaderModalSearch";
import MobileHeaderModalPlaces from "../../../home/home/MobileHeaderModalPlaces/MobileHeaderModalPlaces";
import transformUrlForSearch from "../../../../utils/transformUrlForSearch/transformUrlForSearch";

interface IProps {
	closeModal: () => void;
}

export default function MobileHeaderModal(props:IProps) {
	const router = useRouter()

	const [currentStep, setCurrentStep] = useState<"search" | "places">("search")
	const [searchData, setSearchData] = useState({
		search: "",
		category: "",
		subCategory: "",
		city: "",
		lat: 48.210033,
		lng: 16.363449
	})

	const handleSearch = () => {
		let newUrl = window.location.protocol + "//" + window.location.host + "/posts";
		newUrl = transformUrlForSearch({
			url: newUrl,
			key: "city",
			value: searchData.city
		});
		newUrl = transformUrlForSearch({
			url: newUrl,
			key: "search",
			value: searchData.search
		});
		router.push(newUrl)
	}

	const renderComponent = () => {
		switch (currentStep) {
			case "search":
				return <MobileHeaderModalSearch
					onNext={(data: {search: string, category: string, subCategory: string}) => {
						setSearchData({
							...searchData,
							search: data.search,
							category: data.category,
							subCategory: data.subCategory
						})
						setCurrentStep("places")
					}}
					onBack={() => props.closeModal()}
					data={{
						search: searchData.search,
						category: searchData.category,
						subCategory: searchData.subCategory
					}}
				/>
			case "places":
				return <MobileHeaderModalPlaces
					onNext={(values: {city:string, lat:number, lng:number}) => {
						setSearchData({
							...searchData,
							city: values.city,
							lat: values.lat,
							lng: values.lng
						})
						handleSearch()
					}}
					onBack={() => setCurrentStep("search")}
					placesData={{
						city: searchData.city,
						lat: searchData.lat,
						lng: searchData.lng
					}}
				/>
		}
	}

	return(
		<div>
			{renderComponent()}
		</div>
	)
}
