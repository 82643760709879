import styles from "./HomeMobileLanguagePopup.module.css";
import {useTranslation} from "next-i18next";
import {languages} from "../../../../constants";
import FavouritesService from "../../../../services/Favourites/favourites.service";
import UserService from "../../../../services/User/user.service";

interface IProps {
	closeModal: () => void
}

export default function HomeMobileLanguagePopup(props:IProps) {
	const { t } = useTranslation()
	const { i18n } = useTranslation()

	return(
		<div className={styles.home_mobile_language_popup}>
			<h3>{t("components.mobile_language_popup.language_label")}</h3>
			<div className={styles.home_mobile_language_popup_list}>
				{languages.map((lang:string) => (
					<div
						className={`${styles.home_mobile_language_popup_list_item} ${i18n.language === lang ? styles.selected : ""}`}
						key={lang}
						onClick={() => {
							i18n.changeLanguage(lang)
							new UserService().changeLanguage({language: lang || "de"});
							props.closeModal()
						}}
					>
						<p>{t(`languages.fullName.${lang}`)}</p>
					</div>
				))}
			</div>

		</div>
	)
}
