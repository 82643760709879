import {useState} from "react";
import styles from "./MobileHeaderSearch.module.css";
import {BottomSheet} from "react-spring-bottom-sheet";
import searchIcon from "../../../../img/home/home/search.png";
import languageIcon from "../../../../img/home/home/language.png";
import {useTranslation} from "next-i18next";
import {motion} from "framer-motion";
import MobileHeaderModal from "../../../shared/Modals/MobileHeaderModal/MobileHeaderModal";
import HomeMobileLanguagePopup from "../../../shared/Modals/HomeMobileLanguagePopup/HomeMobileLanguagePopup";

export default function MobileHeaderSearch() {
	const { t } = useTranslation()

	const [showModal, setShowModal] = useState(false)
	const [showLanguageModal, setShowLanguageModal] = useState(false)

	return(
		<div
			className={styles.mobile_header_search_container}
		>

			<BottomSheet
				blocking={true}
				open={showModal}
				skipInitialTransition
				onDismiss={() => setShowModal(false)}
				snapPoints={({ maxHeight }) => [maxHeight - 20]}
			>
				<MobileHeaderModal closeModal={() => setShowModal(false)} />
			</BottomSheet>


			<BottomSheet
				blocking={true}
				open={showLanguageModal}
				skipInitialTransition
				onDismiss={() => setShowLanguageModal(false)}
			>
				<HomeMobileLanguagePopup closeModal={() => setShowLanguageModal(false)} />
			</BottomSheet>

			<header
				className={styles.mobile_header_search}
				onClick={() => setShowModal(true)}
			>
				<motion.img
					src={searchIcon.src}
					alt="search"
				/>
				<p>{t("components.mobile_header_search.search.title")}</p>

				<div
					className={styles.mobile_header_search_region_button}
					onClick={() => setShowLanguageModal(true)}
				>
					<motion.img
						src={languageIcon.src}
						alt="language_icon"
					/>
				</div>

			</header>
		</div>
	)
}
