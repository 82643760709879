import {useCallback, useEffect, useState} from "react";
import styles from "./MobileHeaderModalSearch.module.css";
import searchStyles from "../../../shared/Search/Search.module.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import searchIcon from "../../../../img/home/home/search_white.png";
import searchIconDark from "../../../../img/home/home/search.png";
import {useTranslation} from "next-i18next";
import {requestHandler} from "../../../../utils/requestHandler";
import {url} from "../../../../apiVariables/urls";
import {motion} from "framer-motion";
import chevronLeftIcon from "../../../../img/home/home/chevron_left.png";
import cutStringWithDots from "../../../../utils/cutStringWithDots/cutStringWithDots";
const TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID!;

interface IProps {
	onNext: (data: {search: string, category: string, subCategory: string}) => void;
	onBack: () => void;
	data: {search: string, category: string, subCategory: string};
}

export default function MobileHeaderModalSearch(props:IProps) {
	const { t } = useTranslation()
	const { i18n } = useTranslation();

	/*Layout*/
	const [openList, setOpenList] = useState(false)

	/*Data*/
	const [searchData, setSearchData] = useState([])

	const formik = useFormik({
		initialValues: {
			search: "",
			category: "",
			subCategory: ""
		},
		validationSchema: Yup.object().shape({
			search: Yup.string().required(t("formik_errors.required", {field: t("home.home.main_section.search.search")}))
		}),
		// handle form submitting
		onSubmit: () => {
			props.onNext(formik.values);
		},
	});

	useEffect(() => {
		formik.setValues(props.data)
	}, [props.data])

	const debounce = (func:any) => {
		let timer:any;
		return function (...args:any) {
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				// @ts-ignore
				func.apply(this, args);
			}, 300);
		};
	};

	const handleSearch = (event:any) => {
		const value = event.target.value;
		if (!value) {
			setSearchData([]);
		} else {
			requestHandler({
				path: url.home.post.search,
				method: "GET",
				params: {
					key: value
				}
			}).then((res:any) => {
				if (res && res.status === 200) {
					gtag("event", "search", {
						method: "HomePage",
						send_to: TRACKING_ID
					});
					setOpenList(true);
					setSearchData(res.data);
				} else {
					setSearchData([]);
				}
			}).catch((e) => {
				console.error(e);
			});
		}
	};

	const handleInputChange = useCallback(debounce(handleSearch), []);

	return(
		<div className={styles.mobile_header_modal_search}>
			<div
				onClick={() => props.onBack()}
				className={styles.mobile_header_modal_places_back_btn}
			>
				<motion.img
					src={chevronLeftIcon.src}
					alt="back_icon"
				/>
			</div>
			<h2>{t("components.mobile_header_search.search.title")}</h2>
			<p>{t("components.mobile_header_search.search.example")}</p>
			<form
				onSubmit={formik.handleSubmit}
				className={styles.mobile_header_modal_search_form}
			>
				<input
					type="text"
					onChange={(event:any) => {
						formik.setFieldValue("search", event.target.value, true)
						handleInputChange(event)
					}}
					value={formik.values.search}
					autoFocus
					onBlur={formik.handleBlur("search")}
				/>
				<button
					type={"submit"}
				>
					<motion.img
						src={searchIcon.src}
						alt="search"
					/>
				</button>
				{formik.errors.search && formik.touched.search && (
					<p
						className={styles.customer_search_validation}
					>
						{formik.errors.search}
					</p>
				)}
			</form>
			{searchData?.length > 0 && openList ?
				<div className={styles.main_search_box}>
					{searchData.map((element:any) => (
						<div
							className={searchStyles.main_search_box_item}
							key={element.itemId}
							onClick={() => {
								formik.setFieldValue("search", element.title, true)
								formik.setFieldValue("category", element.category.path, true)
								formik.setFieldValue("subCategory", element.subCategory.path, true)
								setOpenList(false);
							}}
						>
							<div>
								<motion.img
									src={searchIconDark.src}
									alt="search"
								/>
							</div>
							<p>{cutStringWithDots({string: element.title, limit: 15})}</p>
							<p>{">"}</p>
							<p>{cutStringWithDots({string: element.category.name[`${i18n.language}`], limit: 15})}</p>
							<p>{">"}</p>
							<p>{cutStringWithDots({string: element.subCategory.name[`${i18n.language}`], limit: 15})}</p>
						</div>
					))}
				</div>
				: null
			}
		</div>
	)
}
