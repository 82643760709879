interface ITransformUrlForSearch {
	url: string;
	key: "city" | "search";
	value: string | number;
}

export default function transformUrlForSearch(data: ITransformUrlForSearch): string {
	let url = ""
	if (data.key === "city") {
		const splitUrl = data.url.split("/")
		// Replace spaces with "_" and apply as new value for key
		splitUrl[4] = data.value.toString().split(' ').join('_').replace("/", "%2F");
		url = splitUrl.join("/")
	} else {
		const splitUrl = data.url.split("/")
		if (!splitUrl[4]) {
			splitUrl[4] = "Wien"
		}
		splitUrl[5] = data.value.toString().split(' ').join('_').replace("/", "%2F");
		url = splitUrl.join("/")
	}

	return url
}
