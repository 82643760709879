import {useEffect} from "react";
import styles from "./MobileHeaderModalPlaces.module.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import searchIcon from "../../../../img/home/home/search_white.png";
import chevronLeftIcon from "../../../../img/home/home/chevron_left.png"
import {useTranslation} from "next-i18next";
import {motion} from "framer-motion";
import Autocomplete from "../../../shared/Autocomplete/Autocomplete";

interface IProps {
	onNext: (values: {city:string, lat:number, lng:number}) => void;
	onBack: () => void;
	placesData: {city:string, lat:number, lng:number}
}

export default function MobileHeaderModalPlaces(props:IProps) {
	const { t } = useTranslation()

	const formik = useFormik({
		initialValues: {
			city: "",
			lat: 0,
			lng: 0
		},
		validationSchema: Yup.object().shape({
			city: Yup.string().required(t("formik_errors.required", {field: t("home.home.main_section.search.city")}))
		}),
		// handle form submitting
		onSubmit: () => {
			props.onNext(formik.values)
		},
	});

	useEffect(() => {
		formik.setValues(props.placesData)
	}, [props.placesData])

	return(
		<form
			className={styles.mobile_header_modal_places}
			onSubmit={formik.handleSubmit}
			style={{height: "450px"}}
		>
			<div
				onClick={() => props.onBack()}
				className={styles.mobile_header_modal_places_back_btn}
			>
				<motion.img
					src={chevronLeftIcon.src}
					alt="back_icon"
				/>
			</div>
			<h2>{t("components.mobile_header_search.places.title")}</h2>
			<div className={styles.mobile_header_modal_places_search}>
				<Autocomplete
					mobile
					saveAddress={(data:any) => {
						formik.setFieldValue("city", data.city, true);
						formik.setFieldValue("lat", data.lat, true);
						formik.setFieldValue("lng", data.lng, true);
						formik.setFieldError("city", undefined);
					}}
					onBlur={() => formik.handleBlur("city")}
				/>
				{formik.errors.city && formik.touched.city && (
					<p
						className={styles.customer_search_validation}
					>
						{formik.errors.city}
					</p>
				)}
				<button
					type={"submit"}
				>
					<motion.img
						src={searchIcon.src}
						alt="search"
					/>
				</button>
			</div>
		</form>
	)
}
