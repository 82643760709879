interface ICutStringWithDots {
	string: string; // Provided string to be displayed
	limit: number; // Max characters limit after that string ending should be replaced with dots
}

export default function cutStringWithDots(data: ICutStringWithDots): string {
	if (data.string.length > data.limit) {
		return data.string.slice(0, data.limit).concat('...');
	}
	return data.string;
}
